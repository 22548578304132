html.dark {
  background-color: rgb(15, 23, 42);
}

.top-modal-message{
  min-width: 300px;
  margin-bottom: 100px !important;
}

.container-general-style{
  margin-bottom: 100px;
}


.pb-6 {
  padding-top: 2rem;
  padding-bottom: 1.5rem;
}